import React, { Component } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { urlApi } from '../Constants/Global';
import Carousel from 'react-bootstrap/Carousel';

export class SlideBS extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    console.log("slide :: componentDidMount");
  }

  render() {
    return (
      <>
        <Carousel prevLabel="" nextLabel="" className="mb-60">

          { this.props.datas?.map((data, index) => (
          <Carousel.Item interval={5000} key={index} className="bg-img ratio-21x9"style={{backgroundImage: 'url(' + urlApi + '/storage/slide/' + data.row_value + ')'}}>
            <Carousel.Caption>
              <h3>{ data.name }</h3>
              <p className="text-white">{ data.detail }</p>
            </Carousel.Caption>
          </Carousel.Item>
          ))}

        </Carousel>
      </>
    )
  }
}

export default SlideBS