// PRD = /api/2.0
// PRD in subfolder = ../api/2.0
// DEV = http://envphp:8080/api/2.0
// CALL PRD = http://www.yu-pack.com/api/2.0
// CALL PRD HTTPS = https://www.yu-pack.com/api/2.0
const urlApi = "api/2.0";
const useFullUrlApi = false;
const urlApiUpper = useFullUrlApi ? `../${urlApi}` : urlApi;
const baseName = '/';
const apiEndpointMapping = {
  '/': { module: "home", call: 'get-content', useParam: false }, 
  '/Category': { module: "product", call: 'get-items-by-category', useParam: true }, 
  '/Product': { module: "product", call: 'get-item', useParam: true }, 
  '/About': { module: "home", call: 'get-content', useParam: false }, 
  '/Service': { module: "home", call: 'get-content', useParam: false }, 
  '/Payment': { module: "order", call: 'order-get', useParam: true }, 
  '/Contact': { module: "home", call: 'get-content', useParam: false }, 
  '/Profile': { module: "profile", call: 'get', useParam: false }, 
  '/Order': { module: "profile", call: 'get', useParam: false }, 
  '/Security': { module: "profile", call: 'get', useParam: false }, 
  '/ForgetPassword': { module: "profile", call: 'get', useParam: false }, 
  '/ResetPassword': { module: "profile", call: 'reset', useParam: true }, 
  '/ConfirmRegister': { module: "profile", call: 'confirm', useParam: true }, 
  '/CartCheckout': { module: "order", call: 'cart-view', useParam: false }, 
};

export { urlApi, useFullUrlApi, urlApiUpper, baseName, apiEndpointMapping }