import React from 'react';
import { urlApi } from '../Constants/Global';
import { Link } from 'react-router-dom';

const HomeCategory = (props) => {
  return (
    <div className="banner-section pt-60 pb-60">
      <div className="container">
        <div className="row mb-30">
          {props.datas?.map((item, index) => (
            <div className={index === props.datas?.length - 1 ? 'col-lg-12 col-md-12 home-category' : 'col-lg-4 col-md-6 home-category'} key={index}>
              <Link to={"/Category/" + item.id}>
                <div className="category-item" style={{ backgroundImage: 'url("' + urlApi + '/storage/master/' + item.row_value + '")' }}>
                  <h3 className='mb-5 text-right'>{item.name}</h3>
                  <div className='category-info'>
                    <h3 className='mb-5 text-right'>{item.detail}</h3>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

HomeCategory.defaultProps = {
  datas: []
}

export default HomeCategory