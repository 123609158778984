import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { urlApi, useFullUrlApi } from '../Constants/Global';
import NoPhoto from '../Images/default/no-photo.png';
import { Modal, Button } from 'react-bootstrap';
import ProductInfo from './ProductInfo';

function CardItem(props) {
  const [modalShow, setModalShow] = useState(false);
  const [info, setInfo] = useState({});

  const getProductInfo = () => {
    const urlProductInfo = props.apiPath + urlApi + "/?module=product&call=get-item&param=" + props.uid;

    console.log(urlProductInfo);
    fetch(urlProductInfo, {
      method: 'POST'
    })
      .then(res => res.json())
      .then(result => {
        console.log("getProductInfo Result :", result);
        if (result.returnStatus.code === '0000') {
          const productInfo = result.results;

          setInfo(productInfo);
          setModalShow(true);
        }
      });
  }

  const handleClose = () => {
    setModalShow(false);
  }

  const handleShow = () => {
    getProductInfo();
  }

  return (
    <>
      <div className="product-item product-item-2">
        <div className="product-img">
          <div className="product-bg" style={{ backgroundImage: 'url("' + (props.cover !== null ? props.cover : NoPhoto) + '")' }}></div>
        </div>
        <div className="product-info px-3">
          <h6 className="product-title">
            <Link to={'/Product/' + props.uid}>{props.name}</Link>
            <div style={{ color: '#F00', fontSize: '90%' }}>{props.sellingCaption}</div>
          </h6>
          <h3 className="pro-price">
            {props.price}
            {/* <span style={{ cursor: 'pointer' }} title="ใส่ตะกร้า" className='float-right' onClick={() => handleShow()}>
              <i className="zmdi zmdi-shopping-cart-plus"></i>
            </span> */}
          </h3>
        </div>
      </div>

      {/* Modal for Product Info */}
      <Modal size="lg" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="itx-primary">{props.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="itx-dark">
          <ProductInfo apiPath={props.apiPath} datas={info.datas} updateCart={props.updateCart} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CardItem