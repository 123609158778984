import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import HeaderImage from '../Components/HeaderImage';
import { HiOutlineDocumentText } from 'react-icons/hi';
import NavbarProfile from '../Components/NavbarProfile';

function Order(props) {

  const [order, setOrder] = useState('');
  const [orders, setOrders] = useState(props.datas.orders || []);

  const [modalMessage, setModalMessage] = useState(false);
  const [modalMessageTitle, setModalMessageTitle] = useState('');
  const [modalMessageMessage, setModalMessageMessage] = useState('');

  const [modalOrder, setModalOrder] = useState(false);
  const [modalOrderDetail, setModalOrderDetail] = useState('');
  const [modalOrderAddress, setModalOrderAddress] = useState('');
  const [modalOrderLists, setModalOrderLists] = useState([]);
  const [modalOrderSummary, setModalOrderSummary] = useState(0);

  const handleClose = () => {
    setModalMessage(false);
  }

  const handleShow = () => {
    setModalMessage(true);
  }

  const handleOrderClose = () => {
    setModalOrder(false);
  }

  const viewOrder = (item) => {
    console.log(viewOrder, item);
    setOrder(item);
    setModalOrder(true);
    setModalOrderDetail(`${item.firstname} ${item.lastname}`);
    setModalOrderAddress(item.full_address);
    setModalOrderLists(item.lists);
    setModalOrderSummary(item.sum_price);
  }

  useEffect(() => {
    setOrders(props.datas.orders || []);
  }, [props.datas]);

  return (
    <>
      <HeaderImage topic="รายการสั่งซื้อ" />

      <div id="page-content" className="page-wrapper section">

        <div className="login-section mb-80">
          <div className="container">
            <div className="row">
              <div className='col-lg-3'>
                <NavbarProfile />
              </div>

              <div className="col-lg-9">
                <div className="my-account-content" id="accordion">

                  <div className="card mb-15">
                    <div className="card-header">
                      <h4 className="card-title mb-0">
                        <span data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          รายการสั่งซื้อ
                        </span>
                      </h4>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingFour" data-parent="#accordion">
                      <div className="card-body">

                        <div className="p-30">
                          <table>
                            <thead>
                              <tr>
                                <td className='text-center' style={{ width: '180px' }}>วันที่สั่งซื้อ</td>
                                <td className='text-center'>เลขที่สั่งซื้อ</td>
                                <td className='text-center' style={{ width: '120px' }}>สถานะ</td>
                                <td className='text-center' style={{ width: '120px' }}>ยอดรวม</td>
                                <td></td>
                              </tr>
                            </thead>
                            <tbody>
                              {orders.length > 0 ? (
                                orders.map((item, index) => (
                                  <tr key={index}>
                                    <td className='text-center'>{item.update_date}</td>
                                    <td className='text-left'>{item.code}</td>
                                    <td className='text-center'>{item.state_name}</td>
                                    <td className='text-right'>{item.sum_price}</td>
                                    <td>
                                      <button onClick={() => viewOrder(item)}>
                                        <HiOutlineDocumentText size={24} />
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="100%" className="text-center">-- ยังไม่มีรายการ --</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Modal show={modalMessage}>
        <Modal.Header>
          <Modal.Title>{modalMessageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessageMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size={'lg'} show={modalOrder}>
        <Modal.Header className="p-5">
          <Modal.Title>รายการสั่งซื้อ</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5">
          <h3>{modalOrderDetail}</h3>
          <h4>{modalOrderAddress}</h4>
          <table>
            <thead>
              <tr>
                <th className='text-center' style={{ width: '60px' }}>#</th>
                <th className='text-center'>สินค้า</th>
                <th className='text-center' style={{ width: '120px' }}>ราคา</th>
                <th className='text-center' style={{ width: '60px' }}>จำนวน</th>
                <th className='text-center' style={{ width: '120px' }}>รวม</th>
              </tr>
            </thead>
            <tbody>
              {modalOrderLists.map((list, index) => (
                <tr>
                  <td className='text-center'>{index + 1}</td>
                  <td>{list.option_name}</td>
                  <td className='text-right'>{list.price}</td>
                  <td className='text-center'>{list.quantity}</td>
                  <td className='text-right'>{list.price * list.quantity}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-right" colSpan={4}></td>
                <td className="text-right">{modalOrderSummary}</td>
              </tr>
            </tfoot>
          </table>
        </Modal.Body>
        <Modal.Footer>
          {order.state_key === 'order-submit' ?
            <Link to={"/Payment/" + order.id}>
              <Button variant="primary">
                แจ้งชำระเงิน
              </Button>
            </Link>
            : ''}
          <Button variant="secondary" onClick={handleOrderClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

Order.defaultProps = {
  datas: {
    orders: []
  }
}

export default Order