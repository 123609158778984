import React from 'react';
import { Link } from 'react-router-dom';
import { BsDot } from 'react-icons/bs';
import Logo from '../Images/logo/logo.png';
import { FaHome, FaEnvelope, FaPhoneAlt, FaFacebookSquare } from 'react-icons/fa';

function Footer(props) {
  return (
    <footer id="footer" className="footer-area section">
      <div className="footer-top gray-bg">
        <div className="container">
          <div className="footer-top-inner">
            <div className="row">
              <div className="col-xl-4 col-sm-12">
                <div className="single-footer footer-about">
                  <div className="footer-logo">
                    <img src={Logo} alt="footer logo" />
                  </div>
                  <div className="footer-brief">
                    <p>เราเป็นโรงงานผู้ผลิตและจัดจำหน่ายที่ให้ความสำคัญกับรายละเอียดของสินค้าทุกชิ้น เราจึงใส่ใจการใช้งานของลูกค้า และความปลอดภัยของผู้บริโภคเป็นพิเศษ เพราะพวกเราไม่ได้ขายแค่ “Products” แต่เราขาย “Solutions” ด้วย</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-sm-12 d-block d-xl-block d-lg-none d-md-none">
                <div className="single-footer">
                  <h4 className="footer-title border-left">เมนูหลัก</h4>
                  <ul className="footer-menu ps-0">
                    <li>
                      <Link to="/"><BsDot />หน้าแรก</Link>
                    </li>
                    <li>
                      <Link to="/ProductCategory"><BsDot />ผลิตภัณฑ์</Link>
                    </li>
                    <li>
                      <Link to="/About"><BsDot />เกี่ยวกับเรา</Link>
                    </li>
                    {/* <li>
                      <Link to="/Service"><BsDot />บริการของเรา</Link>
                    </li>
                    <li>
                      <Link to="/PaymentSubmit"><BsDot />แจ้งชำระเงิน</Link>
                    </li> */}
                    <li>
                      <Link to="/Contact"><BsDot />ติดต่อเรา</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-sm-12 d-block d-xl-block d-lg-none d-md-none">
                <div className="single-footer">
                  <h4 className="footer-title border-left">กลุ่มผลิตภัณฑ์</h4>
                  <ul className="footer-menu ps-0">
                    {props.categories ? props.categories.map((item, index) => (
                      <li key={index}>
                        <Link to={"/Category/" + item.id}><BsDot />{item.detail}</Link>
                      </li>
                    )) : ''}
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-sm-12">
                <div className="single-footer">
                  <h4 className="footer-title border-left">ติดต่อเรา</h4>
                  <ul className='footer-menu ps-0'>
                    <li>
                      <div className="d-flex">
                        <div><FaHome size={20} className="mr-3" /></div>
                        <div>{props.contact.address}</div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div><FaEnvelope size={20} className='mr-3' /></div>
                        <div>
                          <a href={'mailto:' + props.contact.email}>
                            {props.contact.email}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div><FaPhoneAlt size={20} className='mr-3' /></div>
                        <div>
                          <a href={'tel:' + props.contact.phone}>
                            {props.contact.phone}
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div><FaFacebookSquare size={20} className='mr-3' /></div>
                        <div>
                          <a href={props.contact.facebook} target="_blank" rel="noreferrer">
                            Facebook
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container-fluid">
          <div className="plr-185">
            <div className="copyright">
              <div className="row">
                <div className="col-md-12">
                  <div className="copyright-text text-center">
                    <p>&copy; {props.corporate} 2022. All Rights Reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  corporate: '', 
  contact: {}, 
  categories: []
}

export default Footer