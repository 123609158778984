import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WithRouter from '../Components/WithRouter';
import { urlApi, useFullUrlApi } from '../Constants/Global';
import { BsDot } from 'react-icons/bs';

function ProductInfo({ datas, updateCart, apiPath }) {
  const [displayImg, setDisplayImg] = useState('');
  const [displayPrice, setDisplayPrice] = useState(datas?.item?.price);
  const [selectOption, setSelectOption] = useState([]);
  const [formQuantity, setFormQuantity] = useState(1);

  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalButtonLabel, setModalButtonLabel] = useState('ปิด');
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    // You can perform additional actions or updates here when props change
    // For example, if you want to update childData when apiData changes
    setDisplayPrice(datas?.item?.price);
  }, [datas?.item?.price]); // Add apiData as a dependency to watch for changes

  const handleInputChange = (event) => {
    event.preventDefault();
    console.log("handleInputChange", event.target.value);
    setFormQuantity(event.target.value);
  }

  const handleOptionSelect = (prop, optn) => {
    console.log("handleOptionSelect", prop, optn);
    // Create a shallow copy of datas to avoid mutating it directly
    const updatedDatas = { ...datas };

    // Find the property in updatedDatas
    const updatedProp = updatedDatas.properties.find(p => p.name === prop.name);

    // Toggle is_active for the clicked option
    updatedProp.options.forEach(o => {
      if (o.name === optn.name) {
        o.is_active = true;
      } else {
        o.is_active = false;
      }
    });

    // Set the state with the updated datas
    setSelectOption(updatedDatas);
    console.log("selectOption", updatedDatas);

    // Create an array to store the active options for each property
    const activeOptions = [];

    // Iterate through the properties
    datas.properties.forEach((property) => {
      // Find the active option within the property
      const activeOption = property.options.find((option) => option.is_active);

      // Push the active option into the array
      if (activeOption) {
        activeOptions.push({ property: property, option: activeOption });
      }
    });

    // when select all option get model price
    if (activeOptions.length === datas?.properties.length) {
      console.log("Please, get the model price for me");
      var form_data = new FormData();
      form_data.append("id", datas?.item?.id);
      form_data.append("options", JSON.stringify(activeOptions));

      var url = apiPath + urlApi + '/?module=product&call=get-model-price-by-options';

      fetch(url, {
        method: 'POST',
        body: form_data
      })
        .then(res => res.json())
        .then(result => {
          console.log("getModelPrice", result);
          setDisplayPrice(result.results.price);
        });
    }
  }

  const handleClose = () => {
    setModalShow(false);
  }

  const handleShow = () => {
    setModalShow(true);
  }

  const handleCartAdd = () => {
    console.log("handleCartAdd", urlApi + '/?module=order&call=cart-add');

    // Create an array to store the active options for each property
    const activeOptions = [];

    // Iterate through the properties
    datas.properties.forEach((property) => {
      // Find the active option within the property
      const activeOption = property.options.find((option) => option.is_active);

      // Push the active option into the array
      if (activeOption) {
        activeOptions.push({ property: property, option: activeOption });
      }
    });

    var form_data = new FormData();
    form_data.append("id", datas?.item?.id);
    form_data.append("options", JSON.stringify(activeOptions));
    form_data.append("quantity", formQuantity);

    var url = apiPath + urlApi + '/?module=order&call=cart-add';

    fetch(url, {
      method: 'POST',
      body: form_data
    })
      .then(res => res.json())
      .then(result => {
        console.log("handleCartAdd Result", result);

        if (result.returnStatus.code === "0000") {
          setModalShow(true);
          setModalTitle('ดำเนินการเรียบร้อย');
          setModalMessage('เพิ่มสินค้าลงในตะกร้าเรียบร้อยแล้ว');
          setModalButtonLabel('ปิด');

          updateCart(result.results?.cart);
        } else {
          setModalShow(true);
          setModalTitle('พบข้อผิดพลาด');
          setModalMessage('ไม่สามารถเพิ่มสินค้าลงในตะกร้าได้');
          setModalButtonLabel('ปิด');
        }
      });
  }

  return (
    <>
      <div className="container">
        <div className="row">

          <div className="col-lg-12">
            <div className="single-product-area mb-80">
              <div className="row">
                <div className="col-lg-5">
                  <img className="w-100 mb-4" src={displayImg === '' ? apiPath + urlApi + '/storage/product/' + datas?.item?.row_value : displayImg} alt="" />

                  <ul className="img-lists">
                    <li>
                      <img className='w-100' 
                        onClick={() => setDisplayImg(apiPath + urlApi + '/storage/product/' + datas?.item?.row_value)} 
                        src={apiPath + urlApi + '/storage/product/' + datas?.item?.row_value} 
                        alt="" />
                    </li>
                    {datas?.item?.images.map((img, img_index) => (
                      <li key={img_index}>
                        <img className='w-100' 
                          onClick={() => setDisplayImg(apiPath + urlApi + '/storage/product/' + img?.row_value)} 
                          src={apiPath + urlApi + '/storage/product/' + img?.row_value} 
                          alt="" />
                      </li>
                    ))}
                  </ul>

                </div>
                <div className="col-lg-7">

                  <div className="single-product-info">
                    <h3 className="text-black-1">{datas?.item?.product_name}</h3>
                    <h6 className="brand-name-2">{datas?.item?.category_name}</h6>
                    <hr />
                    <div className="single-pro-color-rating clearfix">
                      {datas?.properties?.map((prop, prop_index) => (
                        <div key={prop_index} className="sin-pro-color f-left w-100 mb-3">
                          <p className="color-title f-left">{prop.name}</p>
                          <div className="f-left option-list">
                            <ul>
                              {prop.options?.map((optn, optn_index) => (
                                <li key={optn_index} className={"d-inline-block px-3 border border-light mx-2 " + (optn.is_active ? 'active' : '')} onClick={() => handleOptionSelect(prop, optn)}>{optn.name}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                    <hr />
                    <div className="plus-minus-pro-action clearfix">
                      <div className="sin-plus-minus f-left clearfix">
                        <p className="color-title f-left">จำนวน</p>
                        <div className="cart-plus-minus f-left">
                          <input type="text" name="quantity" className="cart-plus-minus-box" value={formQuantity} onChange={handleInputChange} />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h3 className="pro-price">ราคา: {displayPrice}</h3>
                    <hr />
                    <div>
                      <Button className="button extra-small button-black" onClick={handleCartAdd}>
                        <span className="text-uppercase">ใส่ตะกร้า</span>
                      </Button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="sm" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="itx-primary">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="itx-dark">
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn ibt-primary px-4" onClick={handleClose}>
            {modalButtonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

ProductInfo.defaultProps = {
  datas: {
    item: {
      price: 0,
      row_value: '',
      images: []
    }
  }
}

export default WithRouter(ProductInfo)