import React, { Component } from 'react';
import { FaLine } from 'react-icons/fa';
import HeaderImage from '../Components/HeaderImage';
import { urlApi } from '../Constants/Global';
import { Modal, Button } from 'react-bootstrap'

export class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: '',
      message: '',
      name: '',
      phone: '',
      modalTitle: '',
      modalMessage: '',
      modalButtonLabel: 'ปิด',
      modalShow: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleClose() {
    this.setState({ modalShow: false });
  }

  handleShow() {
    this.setState({ modalShow: true });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log("messageSent", urlApi + '/?module=web&call=message-submit');

    var form_data = new FormData();
    form_data.append("subject", this.state.subject);
    form_data.append("message", this.state.message);
    form_data.append("name", this.state.name);
    form_data.append("phone", this.state.phone);

    var url = urlApi + '/?module=web&call=message-submit';

    fetch(url, {
      method: 'POST',
      body: form_data
    })
      .then(res => res.json())
      .then(result => {
        console.log("messageSent", result);

        if (result.returnStatus.code === "0000") {
          this.setState({
            subject: '',
            name: '',
            contact_back: '',
            modalShow: true,
            modalTitle: 'ดำเนินการเรียบร้อย',
            modalMessage: 'ได้รับข้อความเรียบร้อย เราจะติดต่อกลับโดยเร็วที่สุด',
            modalButtonLabel: 'ปิด'
          });
        } else {
          this.setState({
            modalShow: true,
            modalTitle: 'ไม่สามารถดำเนินการได้',
            modalMessage: 'ไม่สามารถส่งข้อความได้',
            modalButtonLabel: 'ปิด'
          });
        }
      });
  }

  render() {
    return (
      <>
        <HeaderImage topic="ติดต่อเรา" />

        <section id="page-content" className="page-wrapper section">

          <div className="address-section mb-80">
            <div className="container">
              <div className="row">
                <div className="col-md-4 mt-5">
                  <div className="contact-address box-shadow">
                    <i className="zmdi zmdi-pin"></i>
                    <h3><b>บริษัท ตั้งเติบโต จำกัด</b></h3>
                    <h3>17/3  ม.9  ต.บางแม่นาง  อ.บางใหญ่  จ.นนทบุรี  11140</h3>
                  </div>
                </div>
                <div className="col-md-4 mt-5">
                  <div className="contact-address box-shadow">
                    <i className="zmdi zmdi-phone"></i>
                    <h3><a href="tel:095-092-2963">095-092-2963</a></h3>
                  </div>
                </div>
                <div className="col-md-4 mt-5">
                  <div className="contact-address box-shadow">
                    <i className="zmdi zmdi-email"></i>
                    <h3><a href="mailto:tungterbtoe@gmail.com">tungterbtoe@gmail.com</a></h3>
                  </div>
                </div>
              </div>
              <div class="row">
                <div className="col-md-2"></div>
                <div className="col-md-4 mt-5">
                  <div className="contact-address box-shadow">
                    <i className="zmdi zmdi-facebook-box"></i>
                    <h3><a href="https://www.facebook.com/YuPackPackaging" target="_blank" rel="noreferrer">YuPackPackaging</a></h3>
                  </div>
                </div>
                <div className="col-md-4 mt-5">
                  <div className="contact-address box-shadow">
                    <div className="contact-icon"><FaLine /></div>
                    <h3>LINEID : 0950922963</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="message-box-section mt--50 mb-80">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3873.3177913433724!2d100.3672179!3d13.8799367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb8b1254e7d3f914!2z4Lia4Lij4Li04Lip4Lix4LiXIOC5geC4reC4pSDguJTguLUg4Lie4Lil4Liy4Liq4LmB4Lie4LiEIOC4iOC4s-C4geC4seC4lA!5e0!3m2!1sth!2sth!4v1673324642226!5m2!1sth!2sth" width="100%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="google-maps"></iframe>
                </div>
                <div className="col-lg-6">
                  <div className="message-box box-shadow white-bg">
                    <form id="contact-form" onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12">
                          <h4 className="blog-section-title border-left mb-30">สอบถามข้อมูล</h4>
                        </div>
                        <div className="col-lg-6">
                          <input type="text" name="name" placeholder="ชื่อผู้ติดต่อ"
                            value={this.state.name}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="col-lg-6">
                          <input type="text" name="phone" placeholder="อีเมล์/เบอร์โทรศัพท์ติดต่อกลับ"
                            value={this.state.phone}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="col-lg-12">
                          <input type="text" name="subject" placeholder="เรื่อง"
                            value={this.state.subject}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="col-lg-12">
                          <textarea className="custom-textarea" name="message" placeholder="ข้อความ/รายละเอียด"
                            value={this.state.message}
                            onChange={this.handleInputChange}
                          ></textarea>
                          <button className="submit-btn-1 mt-30 btn-hover-1" type="submit">ส่งข้อความ</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal size="sm" show={this.state.modalShow} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="itx-primary">{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="itx-dark">
            {this.state.modalMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn ibt-primary px-4" onClick={this.handleClose}>
              {this.state.modalButtonLabel}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default Contact