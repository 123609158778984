import React from 'react';
import { urlApi } from '../Constants/Global';
import CardItem from './CardItem';

function HomeProduct(props) {

  function handleChange(v_param) {
    props.handleChange(v_param);
  }

  return (
    <>
      <div className="featured-product-section mb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-left mb-20">
                <h2 className="uppercase">{props.topic}</h2>
                <h4>{props.detail}</h4>
              </div>
            </div>
          </div>
          <div className="featured-product">
            <div className="row active-featured-product slick-arrow-2">
              {props.datas?.map((item, index) => (
                <div className="col-lg-3 col-sm-12" key={index}>
                  <CardItem 
                    uid={item.id} 
                    name={item.product_name} 
                    price={item.price} 
                    cover={item.row_value ? urlApi + '/storage/product/' + item.row_value : item.row_value} 
                    sellingCaption={item.selling_caption} 
                    handleChange={handleChange} 
                    apiPath={''}
                    updateCart={props.updateCart}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeProduct