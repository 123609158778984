import React from 'react';
import HeaderImage from '../Components/HeaderImage';

function ArticleView() {
  return (
    <>
      <HeaderImage topic="บทความ" />

      <section id="page-content" className="page-wrapper section">

        <div className="blog-section mb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <aside className="widget-search mb-30">
                  <form action="#">
                    <input type="text" placeholder="Search here..." />
                    <button type="submit"><i className="zmdi zmdi-search"></i></button>
                  </form>
                </aside>
                <aside className="widget widget-categories box-shadow mb-30">
                  <h6 className="widget-title border-left mb-20">Categories</h6>
                  <div id="cat-treeview" className="product-cat">
                    <ul>
                      <li className="open">Brand Two</li>
                    </ul>
                  </div>
                </aside>
              </div>
              <div className="col-lg-9">
                <div className="blog-details-area">
                  <div className="blog-details-photo bg-img-1 p-20 mb-30">
                    <img src="img/blog/10.jpg" alt="" />
                    <div className="today-date bg-img-1">
                      <span className="meta-date">30</span>
                      <span className="meta-month">June</span>
                    </div>
                  </div>
                  <h3 className="blog-details-title mb-30">Dumm`y single blog name</h3>
                  <div className="blog-description mb-60">
                    <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of plea sure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belon gs to those who fail in their duty through weakness of will, which is the same as saying through shrink ing from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour.</p>

                    <div className="quote-author pl-30">
                      <p className="quote-border pl-30">On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charm of pleas ure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will</p>
                    </div>

                    <p>Which is the same as saying through shrink ing from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour. One the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of plea sure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belong to those who fail in their duty through weakness of will, which is the same as saying through shrink ing from toil and pain. These cases are perfectly simple and easy to distinguish.</p>

                    <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of plea sure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belon gs to those who fail in their duty through weakness of will, which is the same as saying through shrink ing from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour.</p>
                  </div>

                  <div className="blog-share-tags box-shadow clearfix mb-60">
                    <div className="blog-share f-left">
                      <p className="share-tags-title f-left">share</p>
                      <ul className="footer-social f-left">
                        <li>
                          <a className="facebook" href="/#" title="Facebook"><i className="zmdi zmdi-facebook"></i></a>
                        </li>
                        <li>
                          <a className="google-plus" href="/#" title="Google Plus"><i className="zmdi zmdi-google-plus"></i></a>
                        </li>
                        <li>
                          <a className="twitter" href="/#" title="Twitter"><i className="zmdi zmdi-twitter"></i></a>
                        </li>
                        <li>
                          <a className="rss" href="/#" title="RSS"><i className="zmdi zmdi-rss"></i></a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-tags f-right">
                      <p className="share-tags-title f-left">Tags</p>
                      <ul className="blog-tags-list f-left">
                        <li>Mobile</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  )
}

export default ArticleView
