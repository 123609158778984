import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderImage from '../Components/HeaderImage';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';

export class ProfileResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      new_password: '', 
      confirm_password: '', 
      modalShow: false, 
      modalTitle: '', 
      modalMessage: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleClose() {
    this.setState({ modalShow: false });
  }

  handleShow() {
    this.setState({ modalShow: true });
  }

  showMessage(v_title, v_message) {
    this.setState({ modalShow: true, modalTitle: v_title, modalMessage: v_message });
  }

  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.new_password === "") {
      this.showMessage("พบข้อผิดพลาด", "กรุณากรอก Email");
    }else if(this.state.new_password !== this.state.confirm_password){
      this.showMessage("พบข้อผิดพลาด", "ยืนยันรหัสผ่านไม่ถูกต้อง");
    } else {
      var form_data = new FormData();
      form_data.append("password", this.state.new_password);
      form_data.append("confirm_password", this.state.confirm_password);
      form_data.append("key", this.props.datas.key);

      var url = '../' + urlApi + '/?module=profile&call=save_password';

      console.log("handleSubmit", url, this.props.datas.key);

      fetch(url, {
        method: 'POST',
        body: form_data
      })
        .then(res => {
          console.log(res);
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json()
        })
        .then(result => {
          console.log("handleSubmit :", result);
          if (result.returnStatus.code === "0000") {
            this.showMessage("ดำเนินการเรียบร้อย", <>กลับสู่หน้าหลัก<Link to="/">ที่นี่</Link></>);
          } else {
            this.showMessage("พบข้อผิดพลาด", result.returnStatus.topic);
          }
        });
    }
  }

  render() {
    return (
      <>
        <HeaderImage topic="รีเซตรหัสผ่าน" />
        {this.props.datas.key !== '' ? (
          <>
            <div id="page-content" className="page-wrapper section">

              <div className="login-section mb-80">
                <div className="container">
                  <div className="row">
                    <div className='col-lg-3'></div>
                    <div className="col-lg-6">
                      <div className="registered-customers">
                        <h6 className="widget-title border-left mb-50">รีเซตรหัสผ่าน</h6>
                        <form onSubmit={this.handleSubmit}>
                          <div className="login-account p-30 box-shadow">
                            <input type="่password" name="new_password" placeholder="กำหนดรหัสผ่านใหม่"
                              value={this.state.new_password}
                              onChange={this.handleInputChange}
                            />
                            <input type="่password" name="confirm_password" placeholder="ยืนยันรหัสผ่านใหม่"
                              value={this.state.confirm_password}
                              onChange={this.handleInputChange}
                            />
                            <Row>
                              <Col className='text-right'>
                                <button className="submit-btn-1 btn-hover-1" type="submit">ยืนยัน</button>
                              </Col>
                            </Row>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </>
        ) : (
          <>
            <div id="page-content" className="page-wrapper section">

              <div className="login-section mb-80">
                <div className="container">
                  <div className="row">
                    <div className='col-lg-3'></div>
                    <div className="col-lg-6">
                      <div className="registered-customers">
                        <h6 className="widget-title border-left mb-50">ไม่สามารถรีเซตรหัสผ่านได้</h6>
                        <p className='text-center'>Key ของคุณไม่ถูกต้อง กลับสู่หน้าหลัก<Link to="/">ที่นี่</Link></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </>
        )}

        <Modal show={this.state.modalShow} onHide={this.handleClose} centered>
          <Modal.Header className="p-4" closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">{this.state.modalMessage}</Modal.Body>
          <Modal.Footer className="p-4">
            <Button variant="secondary" onClickCapture={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

ProfileResetPassword.defaultProps = {
  datas: {}
}

export default ProfileResetPassword