import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { urlApi, baseName } from './Constants/Global';
import ObjRouteManager from './Components/ObjRouteManager';
import ScrollToTop from './Components/ScrollToTop';
import './Libs/bs-carousel/css/bootstrap.min.css';
import SiteHelmet from './Components/SiteHelmet';
import favicon from './Images/logo/favicon.png';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Temporary from './Pages/Temporary';
import Home from './Pages/Home';
import CategoryView from './Pages/CategoryView';
import Category from './Pages/Category';
import ProductView from './Pages/ProductView';
import Article from './Pages/Article';
import ArticleView from './Pages/ArticleView';
import Contact from './Pages/Contact';
import Payment from './Pages/Payment';
import NoContent from './Pages/NoContent';
import Profile from './Pages/Profile';
import Order from './Pages/Order';
import Security from './Pages/Security';
import ProfileForget from './Pages/ProfileForget';
import ProfileResetPassword from './Pages/ProfileResetPassword';
import Login from './Pages/Login';
import ConfirmRegister from './Pages/ConfirmRegister';
import Cart from './Pages/Cart';
import CartCheckout from './Pages/CartCheckout';
import CartResult from './Pages/CartResult';
import { MessengerChat } from 'react-messenger-chat-plugin';

function App() {
  const isTemporary = false;
  const [datas, setDatas] = useState({});

  // for develop with production api
  // datas.webuser = 'devdazzle@gmail.com';

  const routeChangeHandler = (contents) => {
    console.log('routeChangeHandler', contents);
    setDatas(contents);
  }

  const handleCartChange = (new_cart) => {
    console.log("handleCartchange", new_cart);
    const newDatas = { ...datas };
    newDatas.cart = new_cart;
    setDatas(newDatas);
  }

  return (
    <>
      {isTemporary ? <Temporary /> : (
        <BrowserRouter basename={baseName}>
          <MessengerChat pageId='211631659680293' />
          <ObjRouteManager changeHandler={routeChangeHandler}></ObjRouteManager>
          <ScrollToTop>
            <SiteHelmet favicon={favicon} author="yu-pack" title="YU Pack" />
            <div className="wrapper">
              <Navbar datas={datas} />
              <Routes>
                <Route path="/" element={<Home datas={datas.datas} updateCart={handleCartChange} />} />
                <Route path="/Product/:id" element={<ProductView updateCart={handleCartChange} datas={datas.datas} />} />
                <Route path="/Category" element={<Category categories={datas.categories} />} />
                <Route path="/Category/:id" element={<CategoryView updateCart={handleCartChange} datas={datas.datas} />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Profile" element={datas.webuser !== '' ? <Profile datas={datas.datas} /> : <Login />} />
                <Route path="/Order" element={datas.webuser !== '' ? <Order datas={datas.datas} /> : <Login />} />
                <Route path="/Security" element={datas.webuser !== '' ? <Security datas={datas.datas} /> : <Login />} />
                {/* <Route path="/Payment/:id" element={datas.webuser !== '' ? <Payment datas={datas.datas} /> : <Login />} />
                <Route path="/ConfirmRegister/:key" element={<ConfirmRegister datas={datas.datas} />} />
                <Route path="/ForgetPassword" element={<ProfileForget datas={datas.datas} />} />
                <Route path="/ResetPassword/:key" element={<ProfileResetPassword datas={datas.datas} />} />
                <Route path="/CartCheckout" element={<CartCheckout updateCart={handleCartChange} datas={datas.datas} />} /> */}

                {/* <Route path="/About" element={<ArticleView slug="about" />} />
                <Route path="/Service" element={<ArticleView slug="service" />} /> */}
                <Route path="/About" element={<NoContent />} />
                <Route path="/Service" element={<NoContent />} />

                {/* <Route path="/Article" element={<Article />} />
                <Route path="/ArticleView/:id" element={<ArticleView />} /> */}

                {/* <Route path="/Login" element={<Login />} />
                <Route path="/Cart" element={<Cart />} />
                <Route path="/CartResult" element={<CartResult />} /> */}
              </Routes>
              <Footer corporate="Yu Pack" categories={datas.categories} contact={datas.contact} />
            </div>
          </ScrollToTop>
        </BrowserRouter>
      )}
    </>
  )
}

export default App