import React, { Component } from 'react';
import { FaLine } from 'react-icons/fa';
import Logo from '../Images/logo/logo.png';

export class Temporary extends Component {
  render() {
    return (
      <>
        <div className="wrapper">
          
          <div className="slider-area plr-185 mt-80 mb-80 section">
            <div className="container-fluid">
              <div className="slider-content">
                <div className="active-slider-1 slick-arrow-1 slick-dots-1">

                  <div className="col-lg-12">
                    <div className="layer-1">
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="slider-img" style={{width: '100%'}}>
                            <img src={Logo} alt="main logo" />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="gray-bg p-5" style={{width: '100%', height: 'auto'}}>
                            <div className="">
                              <h1 className="text-uppercase text-black-1 font-default">Food Grade Packaging</h1>
                              <div className="text-black-2 font-default">
                                <p className="fs-5">บรรจุภัณฑ์เพื่อความปลอดภัย</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <section id="page-content" className="page-wrapper section">

            <div className="address-section mb-80">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 mt-5">
                    <div className="contact-address box-shadow">
                      <i className="zmdi zmdi-pin"></i>
                      <h3><b>บริษัท ตั้งเติบโต จำกัด</b></h3>
                      <h3>17/3  ม.9  ต.บางแม่นาง  อ.บางใหญ่  จ.นนทบุรี  11140</h3>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="contact-address box-shadow">
                      <i className="zmdi zmdi-phone"></i>
                      <h3><a href="tel:095-092-2963">095-092-2963</a></h3>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="contact-address box-shadow">
                      <i className="zmdi zmdi-email"></i>
                      <h3><a href="mailto:tungterbtoe@gmail.com">tungterbtoe@gmail.com</a></h3>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4 mt-5">
                    <div className="contact-address box-shadow">
                      <i className="zmdi zmdi-facebook-box"></i>
                      <h3><a href="https://www.facebook.com/YuPackPackaging" target="_blank" rel="noreferrer">YuPackPackaging</a></h3>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <div className="contact-address box-shadow">
                      <div className="contact-icon"><FaLine/></div>
                      <h3>LINEID : 0950922963</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer id="footer" className="footer-area section">
            <div className="footer-bottom black-bg">
              <div className="container-fluid">
                <div className="plr-185">
                  <div className="copyright">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div className="copyright-text">
                          <p>&copy; YU Pack 2022. All Rights Reserved.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    )
  }
}

export default Temporary