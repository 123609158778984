import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderImage from '../Components/HeaderImage';

export class Article extends Component {
  render() {
    return (
      <>
        <HeaderImage topic="ไม่พบข้อมูล"/>
        
        <div id="page-content" className="page-wrapper section">
          <div className="blog-section mb-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h3>ขออภัย เนื้อหาอยู่ระหว่างจัดเตรียม</h3>
                  <p>สอบถามข้อมูลเพิ่มเติมได้<Link to="/Contact">ที่นี่</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Article