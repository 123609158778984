import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';

const HomeService = (props) => {
  return (
    <>
      <div className="banner-section pt-60 pb-60">
        <div className="container">
          <div className='row'>
            <div className="col-lg-12">
              <div className="section-title text-center mb-30">
                <h2 className="uppercase mb-15">บริการของเรา</h2>
              </div>
            </div>
          </div>
          <div className="row mb-30">
            {props.datas?.map((item, index) => (
              <div className="col-lg-3 col-md-6" key={index}>
                <div className="service-item text-center">
                  <img src={urlApi + '/storage/media/' + item.set3} alt={'service ' + index} className="mb-3" />
                  <h3 className='mb-5'>{item.set1}</h3>
                </div>
              </div>
            ))}
          </div>
          {/* <div className='row'>
            <div className="col-lg-12">
              <div className="section-title text-center mb-30">
                <Link to="/Service"><button className='btn-service'>รายละเอียดเพิ่มเติม</button></Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default HomeService