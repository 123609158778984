import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import HeaderImage from '../Components/HeaderImage';
import { HiOutlineDocumentText } from 'react-icons/hi';
import NavbarProfile from '../Components/NavbarProfile';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

function Profile(props) {

  const [profile, setProfile] = useState(props.datas.profile || {});
  const [provinces, setProvinces] = useState(props.datas.provinces || []);
  const [amphurs, setAmphurs] = useState(props.datas.amphurs || []);
  const [districts, setDistricts] = useState(props.datas.districts || []);

  const [pass_current, setPassword] = useState('');
  const [pass_view, setPassView] = useState(false);

  const [modalMessage, setModalMessage] = useState(false);
  const [modalMessageTitle, setModalMessageTitle] = useState('');
  const [modalMessageMessage, setModalMessageMessage] = useState('');

  const [modalOrder, setModalOrder] = useState(false);
  const [modalOrderDetail, setModalOrderDetail] = useState('');
  const [modalOrderAddress, setModalOrderAddress] = useState('');
  const [modalOrderLists, setModalOrderLists] = useState([]);
  const [modalOrderSummary, setModalOrderSummary] = useState(0);

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === 'pass_current') {
      setPassword(value);
    } else {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [name]: value,
      }));
    }

    if (name === 'province_id') {
      setAmphurs([]);
      setDistricts([]);
      setProfile((prevProfile) => ({
        ...prevProfile,
        zipcode: '',
      }));
      getGeo(value, 'amphur');
    } else if (name === 'amphur_id') {
      setDistricts([]);
      setProfile((prevProfile) => ({
        ...prevProfile,
        zipcode: '',
      }));
      getGeo(value, 'district');
    } else if (name === 'district_id') {
      setProfile((prevProfile) => ({
        ...prevProfile,
        zipcode: '',
      }));
      getGeo(value, 'zipcode');
    }
  }

  const handlePassView = () => {
    setPassView(!pass_view);
  }

  const handleClose = () => {
    setModalMessage(false);
  }

  const handleShow = () => {
    setModalMessage(true);
  }

  const handleOrderClose = () => {
    setModalOrder(false);
  }

  const saveProfile = (event) => {
    console.log("saveProfile:", profile);
    event.preventDefault();

    if (pass_current === '') {
      setModalMessageTitle("พบข้อผิดพลาด");
      setModalMessageMessage("กรุณากรอกรหัสผ่านของท่าน");
      handleShow();
    } else if (profile.firstname === '') {
      setModalMessageTitle("พบข้อผิดพลาด");
      setModalMessageMessage("กรุณากรอกชื่อ");
      handleShow();
    } else {
      const formData = new FormData();
      formData.append("firstname", profile.firstname);
      formData.append("lastname", profile.lastname);
      formData.append("email", profile.email);
      formData.append("phone", profile.phone);
      formData.append("address1", profile.address1);
      formData.append("province_id", profile.province_id);
      formData.append("amphur_id", profile.amphur_id);
      formData.append("district_id", profile.district_id);
      formData.append("zipcode", profile.zipcode);
      formData.append("pass_current", pass_current);

      var url = urlApi + '/?module=profile&call=save';
      fetch(url, {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(result => {
          console.log("saveProfile", result);

          if (result.returnStatus.code === "0000") {
            setPassword('');
            setModalMessageTitle("ดำเนินการเรียบร้อย");
            setModalMessageMessage("บันทึกข้อมูลเรียบร้อย");
            handleShow();
          } else {
            setModalMessageTitle("พบข้อผิดพลาด");
            setModalMessageMessage(result.returnStatus.detail);
            handleShow();
          }
        });
    }
  }

  const getGeo = (id, type) => {
    console.log("getGeo", id, type);

    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", type);

    var url = urlApi + '/?module=web&call=geo-get-contents';
    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(result => {
        console.log("getGeo", result);

        if (result.returnStatus.code === "0000") {
          if (type === 'amphur') {
            setAmphurs(result.results);
          } else if (type === 'district') {
            setDistricts(result.results);
          } else if (type === 'zipcode') {
            setProfile((prevProfile) => ({
              ...prevProfile,
              zipcode: result.results,
            }));
          }
        }
      });
  }

  useEffect(() => {
    setProfile(props.datas.profile || {});
    setProvinces(props.datas.provinces || []);
    setAmphurs(props.datas.amphurs || []);
    setDistricts(props.datas.districts || []);
  }, [props.datas]);

  return (
    <>
      <HeaderImage topic="ข้อมูลส่วนตัว" />

      <div id="page-content" className="page-wrapper section">

        <div className="login-section mb-80">
          <div className="container">
            <div className="row">
              <div className='col-lg-3'>
                <NavbarProfile />
              </div>
              <div className="col-lg-9">
                <div className="my-account-content" id="accordion">

                  <div className="card mb-15">
                    <div className="card-header" id="headingOne">
                      <h4 className="card-title mb-0">
                        <span data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          ข้อมูลส่วนตัว
                        </span>
                      </h4>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                      <div className="card-body">
                        <form onSubmit={saveProfile}>
                          <div className="new-customers">
                            <div className="p-30">
                              <div className="row">
                                <div className="col-sm-6">
                                  <input type="text" name="firstname" placeholder="ชื่อ"
                                    value={profile.firstname}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <input type="text" name="lastname" placeholder="นามสกุล"
                                    value={profile.lastname}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <input type="text" name="email" placeholder="Email"
                                    value={profile.email}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <input type="text" name="phone" placeholder="เบอร์โทร"
                                    value={profile.phone}
                                    onChange={handleInputChange}
                                  />
                                </div>

                                <div className="col-sm-12">
                                  <input type="text" name="address1" placeholder="ที่อยู่"
                                    value={profile.address1}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <select className="custom-select" name="province_id"
                                    value={profile.province_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">จังหวัด</option>
                                    {provinces ? provinces.map((item, index) => (
                                      <option value={item.id} key={index}>{item.name_th}</option>
                                    )) : ''}
                                  </select>
                                </div>
                                <div className="col-sm-6">
                                  <select className="custom-select" name="amphur_id"
                                    value={profile.amphur_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">เขต/อำเภอ</option>
                                    {amphurs ? amphurs.map((item, index) => (
                                      <option value={item.id} key={index}>{item.name_th}</option>
                                    )) : ''}
                                  </select>
                                </div>
                                <div className="col-sm-6">
                                  <select className="custom-select" name="district_id"
                                    value={profile.district_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">แขวง/ตำบล</option>
                                    {districts ? districts.map((item, index) => (
                                      <option value={item.id} key={index}>{item.name_th}</option>
                                    )) : ''}
                                  </select>
                                </div>
                                <div className="col-sm-6">
                                  <input type="text" name="zipcode" placeholder='รหัสไปรษณีย์'
                                    readOnly="readonly"
                                    value={profile.zipcode}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <InputGroup>
                                    <Form.Control
                                      type={pass_view ? 'text' : 'password'}
                                      name="pass_current" placeholder="กรอกรหัสผ่านปัจจุบันเพื่อบันทึกข้อมูล"
                                      value={pass_current}
                                      onChange={handleInputChange}
                                      autoComplete="current-password"
                                      aria-label="Password"
                                      aria-describedby="basic-addon1"
                                    />
                                    <InputGroup.Text id="basic-addon1" className='px-3'>
                                      {pass_view ?
                                        <FaRegEye size={20} className='cursor-pointer' onClick={handlePassView} />
                                        :
                                        <FaRegEyeSlash size={20} className='cursor-pointer' onClick={handlePassView} />
                                      }
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>
                                <div className="col-md-6">
                                  <button className="submit-btn-1 btn-hover-1" type="submit"
                                    value="register">บันทึก</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Modal show={modalMessage}>
        <Modal.Header>
          <Modal.Title>{modalMessageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessageMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

Profile.defaultProps = {
  datas: {
    profile: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      address1: '',
      province_id: '',
      amphur_id: '',
      district_id: '',
      zipcode: ''
    },
    provinces: [],
    amphurs: [],
    districts: []
  }
}

export default Profile