import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import HeaderImage from '../Components/HeaderImage';
import { urlApi } from '../Constants/Global';
import { Container, Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';

function CartCheckout({ datas, updateCart }) {
  const navigate = useNavigate();

  const [profile, setProfile] = useState(datas.profile || {});
  const [cart, setCart] = useState(datas.cart || []);
  const [provinces, setProvinces] = useState(datas.provinces || []);
  const [amphurs, setAmphurs] = useState(datas.amphurs || []);
  const [districts, setDistricts] = useState(datas.districts || []);
  const [targetItem, setTargetItem] = useState('');
  const [shippingPrice, setShippingPrice] = useState(datas.shippingPrice);

  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalButtonLabel, setModalButtonLabel] = useState('ปิด');
  const [modalButtonHome, setModalButtonHome] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [modalConfirmTitle, setModalConfirmTitle] = useState('');
  const [modalConfirmMessage, setModalConfirmMessage] = useState('');
  const [modalConfirmButtonLabel, setModalConfirmButtonLabel] = useState('ปิด');
  const [modalConfirmShow, setModalConfirmShow] = useState(false);

  const handleCartChange = (event, index) => {
    const newCart = [...cart];
    newCart[index].quantity = event.target.value;

    var form_data = new FormData();
    form_data.append("item", JSON.stringify(newCart[index]));
    form_data.append("province_id", profile.province_id);

    var url = urlApi + '/?module=order&call=cart-update';
    fetch(url, {
      method: 'POST',
      body: form_data
    })
      .then(res => res.json())
      .then(result => {
        console.log("submitOrder Result", result);

        if (result.returnStatus.code === "0000") {
          setCart(result.results.cart);
          setShippingPrice(result.results.shippingPrice);
        } else {
          setModalShow(true);
          setModalTitle('พบข้อผิดพลาด');
          setModalMessage('ไม่สามารถแก้ไขจำนวนได้');
          setModalButtonLabel('ปิด');
          setModalButtonHome(false);
        }
      });
  };

  const handleRemoveConfirm = (event, index) => {
    event.preventDefault();
    console.log("handleRemoveConfirm", index);
    setTargetItem(index);
    setModalConfirmTitle("ยืนยันการทำรายการ");
    setModalConfirmMessage("คุณต้องการลบข้อมูล?");
    setModalConfirmButtonLabel("ตกลง");
    setModalConfirmShow(true);
  }

  const handleCartRemove = (index) => {
    console.log('handleCartRemove', index, cart[index]);
    setModalConfirmShow(false);

    const newCart = [...cart];

    var form_data = new FormData();
    form_data.append("item", JSON.stringify(newCart[index]));

    var url = urlApi + '/?module=order&call=cart-remove';
    fetch(url, {
      method: 'POST',
      body: form_data
    })
      .then(res => res.json())
      .then(result => {
        console.log("handleCartRemove Result", result);

        if (result.returnStatus.code === "0000") {
          setCart(result.results.cart);

          updateCart(result.results?.cart);
        } else {
          setModalShow(true);
          setModalTitle('พบข้อผิดพลาด');
          setModalMessage('ไม่สามารถลบได้');
          setModalButtonLabel('ปิด');
          setModalButtonHome(false);
        }
      });
  }

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));

    if (name === 'province_id') {
      setAmphurs([]);
      setDistricts([]);
      setProfile((prevProfile) => ({
        ...prevProfile,
        zipcode: '',
      }));

      getGeo(value, 'amphur');

      // update shipping
      console.log(profile);
      const newCart = { id: '' };

      var form_data = new FormData();
      form_data.append("item", JSON.stringify(newCart));
      form_data.append("province_id", event.target.value);

      var url = urlApi + '/?module=order&call=cart-update';
      fetch(url, {
        method: 'POST',
        body: form_data
      })
        .then(res => res.json())
        .then(result => {
          console.log("submitOrder Result", result);

          if (result.returnStatus.code === "0000") {
            setCart(result.results.cart);
            setShippingPrice(result.results.shippingPrice);
          }
        });

    } else if (name === 'amphur_id') {
      setDistricts([]);
      setProfile((prevProfile) => ({
        ...prevProfile,
        zipcode: '',
      }));
      getGeo(value, 'district');
    } else if (name === 'district_id') {
      setProfile((prevProfile) => ({
        ...prevProfile,
        zipcode: '',
      }));
      getGeo(value, 'zipcode');
    }
  }

  const handleClose = () => {
    setModalShow(false);
  }

  const handleShow = () => {
    setModalShow(true);
  }

  const handleConfirmClose = () => {
    setModalConfirmShow(false);
  }

  const handleGoTo = (targetPage) => {
    console.log("handleGoTo", targetPage);
    setModalShow(false);

    navigate.push(`/${targetPage}`);
  }

  const calculateSum = () => {
    return cart.reduce((total, currentValue) => total + (currentValue.quantity * currentValue.price), 0) + parseFloat(shippingPrice);
  };

  const submitOrder = (event) => {
    event.preventDefault();
    console.log("submitOrder");

    var form_data = new FormData();
    form_data.append("profile", JSON.stringify(profile));
    form_data.append("cart", JSON.stringify(cart));

    var url = urlApi + '/?module=order&call=order-submit';

    fetch(url, {
      method: 'POST',
      body: form_data
    })
      .then(res => res.json())
      .then(result => {
        console.log("submitOrder Result", result);

        if (result.returnStatus.code === "0000") {
          setModalShow(true);
          setModalTitle('ดำเนินการเรียบร้อย');
          setModalMessage('สั่งซื้อสินค้าเรียบร้อย');
          setModalButtonLabel(<Link to="/" style={{color: '#FFF'}}>ไปหน้าแรก</Link>);
          setModalButtonHome(true);
        } else {
          setModalShow(true);
          setModalTitle('พบข้อผิดพลาด');
          setModalMessage('ไม่สามารถสั่งซื้อสินค้าได้');
          setModalButtonLabel('ปิด');
          setModalButtonHome(false);
        }
      });
  }

  const getGeo = (id, type) => {
    console.log("getGeo", id, type);

    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", type);

    var url = urlApi + '/?module=web&call=geo-get-contents';
    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(result => {
        console.log("getGeo", result);

        if (result.returnStatus.code === "0000") {
          if (type === 'amphur') {
            setAmphurs(result.results);
          } else if (type === 'district') {
            setDistricts(result.results);
          } else if (type === 'zipcode') {
            setProfile((prevProfile) => ({
              ...prevProfile,
              zipcode: result.results,
            }));
          }
        }
      });
  }

  useEffect(() => {
    setProfile(datas.profile || {});
    setCart(datas.cart || []);
    setProvinces(datas.provinces || []);
    setAmphurs(datas.amphurs || []);
    setDistricts(datas.districts || []);
    setShippingPrice(datas.shippingPrice || 0);
  }, [datas]);

  return (
    <>
      <HeaderImage topic="" />

      <section id="page-content" className="page-wrapper section">

        <div className="shop-section mb-80">
          <Container>
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                <div className="checkout-content box-shadow p-30">
                  <Form onSubmit={submitOrder}>
                    <Row>
                      <Col lg={{ span: 12, offset: 0 }}>
                        <div className="payment-details pl-10 mb-20">
                          <h6 className="widget-title border-left">ตะกร้าสินค้า</h6>
                          <table>
                            <thead>
                              <tr>
                                <td>สินค้า</td>
                                <td>จำนวน</td>
                                <td>ราคา</td>
                                <td>รวม</td>
                                <td></td>
                              </tr>
                            </thead>
                            <tbody>
                              {cart.length > 0 ? cart.map((item, index) => (
                                <tr key={index}>
                                  <td className="td-title-1" style={{ fontSize: '18px' }}>{item.name}</td>
                                  <td className="td-title-2">
                                    <Form.Control type="number" className="text-right" value={item.quantity} onChange={(event) => handleCartChange(event, index)} />
                                  </td>
                                  <td className="td-title-2">{item.price.toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                  <td className="td-title-2">{(item.price * item.quantity).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                  <td className="text-center">
                                    <button onClick={(event) => handleRemoveConfirm(event, index)}>
                                      <MdClose />
                                    </button>
                                  </td>
                                </tr>
                              )) : (
                                <tr>
                                  <td className="td-title-1 text-center" style={{ fontSize: '18px' }} colSpan='100%'>ยังไม่มีรายการ</td>
                                </tr>
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td className="td-title-2" colSpan={3}>ค่าจัดส่ง</td>
                                <td className="td-title-2">{shippingPrice}</td>
                              </tr>
                              <tr>
                                <td className="td-title-2" colSpan={3}>รวมทั้งสิ้น</td>
                                <td className="td-title-2">{calculateSum().toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="payment-details pl-10 mb-20">
                          <h6 className="widget-title border-left">การจัดส่ง</h6>
                          <div className="new-customers">
                            <div className="p-30">
                              <div className="row">
                                <div className="col-sm-4">
                                  <input type="text" name="firstname" placeholder="ชื่อ"
                                    value={profile.firstname}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <input type="text" name="lastname" placeholder="นามสกุล"
                                    value={profile.lastname}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <input type="text" name="phone" placeholder="เบอร์โทร"
                                    value={profile.phone}
                                    onChange={handleInputChange}
                                  />
                                </div>

                                <div className="col-sm-12">
                                  <input type="text" name="address1" placeholder="ที่อยู่"
                                    value={profile.address1}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="col-sm-6">
                                  <select className="custom-select" name="province_id"
                                    value={profile.province_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">จังหวัด</option>
                                    {provinces ? provinces.map((item, index) => (
                                      <option value={item.id} key={index}>{item.name_th}</option>
                                    )) : ''}
                                  </select>
                                </div>
                                <div className="col-sm-6">
                                  <select className="custom-select" name="amphur_id"
                                    value={profile.amphur_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">เขต/อำเภอ</option>
                                    {amphurs ? amphurs.map((item, index) => (
                                      <option value={item.id} key={index}>{item.name_th}</option>
                                    )) : ''}
                                  </select>
                                </div>
                                <div className="col-sm-6">
                                  <select className="custom-select" name="district_id"
                                    value={profile.district_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">แขวง/ตำบล</option>
                                    {districts ? districts.map((item, index) => (
                                      <option value={item.id} key={index}>{item.name_th}</option>
                                    )) : ''}
                                  </select>
                                </div>
                                <div className="col-sm-6">
                                  <input type="text" name="zipcode" placeholder='รหัสไปรษณีย์'
                                    readOnly="readonly"
                                    value={profile.zipcode}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='text-right'>
                        <button className="submit-btn-1 mt-30 btn-hover-1" type="submit">ยืนยันการสั่งซื้อ</button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

      </section>

      <Modal size="sm" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="itx-primary">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="itx-dark">
          {modalMessage}
        </Modal.Body>
        <Modal.Footer className="btn ibt-primary p-4">
          {modalButtonHome ?
            <Button className="btn ibt-primary px-4" onClick={() => handleGoTo('Home')}>
              {modalButtonLabel}
            </Button>
            :
            <Button className="btn ibt-primary px-4" onClick={handleClose}>
              {modalButtonLabel}
            </Button>
          }
        </Modal.Footer>
      </Modal>

      <Modal size="sm" show={modalConfirmShow} onHide={handleConfirmClose}>
        <Modal.Header closeButton>
          <Modal.Title className="itx-primary">{modalConfirmTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="itx-dark">
          {modalConfirmMessage}
        </Modal.Body>
        <Modal.Footer className="btn ibt-primary p-4">
          <Button className="btn ibt-primary px-4" onClick={() => handleCartRemove(targetItem)}>
            {modalConfirmButtonLabel}
          </Button>
          <Button className="btn px-4" onClick={handleConfirmClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

CartCheckout.defaultProps = {
  datas: {
    profile: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      address1: '',
      province_id: '',
      amphur_id: '',
      district_id: '',
      zipcode: ''
    },
    cart: [],
    provinces: [],
    amphurs: [],
    districts: [],
    shippingPrice: 0
  }
}

export default CartCheckout