import React from 'react';
import HomeCategory from '../Components/HomeCategory';

const ProductCategory = (props) => {
  return (
    <>
      <HomeCategory datas={props.categories} />
    </>
  )
}

ProductCategory.defaultProps = {
  categories: []
}

export default ProductCategory