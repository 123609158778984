import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderImage from '../Components/HeaderImage';
import { Row, Col, Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import parse from 'html-react-parser';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login_email: '',
      login_pass: '',
      regis_firstname: '',
      regis_lastname: '',
      regis_email: '',
      regis_pass: '',
      regis_pass_confirm: '',
      modalShow: false,
      modalTitle: '',
      modalMessage: '',
      login_pass_view: false,
      regis_pass_view: false,
      regis_pass_confirm_view: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleLoginPassView = this.handleLoginPassView.bind(this);
    this.handleRegisPassView = this.handleRegisPassView.bind(this);
    this.handleRegisConfirmPassView = this.handleRegisConfirmPassView.bind(this);
  }

  handleLoginPassView() {
    this.setState({ login_pass_view: !this.state.login_pass_view });
  }

  handleRegisPassView() {
    this.setState({ regis_pass_view: !this.state.regis_pass_view });
  }

  handleRegisConfirmPassView() {
    this.setState({ regis_pass_confirm_view: !this.state.regis_pass_confirm_view });
  }

  handleClose() {
    this.setState({ modalShow: false });
  }

  handleShow() {
    this.setState({ modalShow: true });
  }

  showMessage(v_title, v_message) {
    this.setState({ modalShow: true, modalTitle: v_title, modalMessage: v_message });
  }

  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleRegister(event) {
    event.preventDefault();

    if (this.state.regis_firstname === "") {
      this.showMessage("พบข้อผิดพลาด", "กรุณากรอกชื่อ");
    } else if (this.state.regis_lastname === "") {
      this.showMessage("พบข้อผิดพลาด", "กรุณากรอกนามสกุล");
    } else if (this.state.regis_email === "") {
      this.showMessage("พบข้อผิดพลาด", "กรุณากรอก Email ให้ถูกต้อง");
    } else if (this.state.regis_pass === "" || this.state.regis_pass.length < 6) {
      this.showMessage("พบข้อผิดพลาด", parse("กรุณากรอกรหัสผ่านให้ถูกต้อง<br>รหัสผ่านต้องมากกว่า 6 ตัวอักษร"));
    } else if (this.state.regis_pass !== this.state.regis_pass_confirm) {
      this.showMessage("พบข้อผิดพลาด", "กรุณากรอกยืนยันรหัสผ่านให้ตรงกัน" + this.state.regis_pass + ', ' + this.state.regis_pass_confirm);
    } else {
      var form_data = new FormData();
      form_data.append("firstname", this.state.regis_firstname);
      form_data.append("lastname", this.state.regis_lastname);
      form_data.append("email", this.state.regis_email);
      form_data.append("password", this.state.regis_pass);
      form_data.append("password_confirm", this.state.regis_pass_confirm);

      var url = urlApi + '/?module=profile&call=register';

      fetch(url, {
        method: 'POST',
        body: form_data
      })
        .then(res => res.json())
        .then(result => {
          console.log("register", result);

          if (result.returnStatus.code === "0000") {
            this.setState({
              regis_firstname: '',
              regis_lastname: '',
              regis_email: '',
              regis_pass: '',
              regis_pass_confirm: ''
            });

            this.showMessage("ดำเนินการเรียบร้อย", "สมัครสมาชิกเรียบร้อย ยืนยันการสมัครได้ที่ Email ของคุณ");
          } else {
            this.showMessage("พบข้อผิดพลาด", result.returnStatus.topic);
          }
        });
    }
  }

  handleLogin(event) {
    console.log("handleLogin:");
    event.preventDefault();

    if (this.state.login_email === "") {
      this.showMessage("พบข้อผิดพลาด", "กรุณากรอก Email");
    } else if (this.state.login_pass === "") {
      this.showMessage("พบข้อผิดพลาด", "กรุณากรอกรหัสผ่าน");
    } else {
      var form_data = new FormData();
      form_data.append("user", this.state.login_email);
      form_data.append("pass", this.state.login_pass);

      var url = urlApi + '/?module=profile&call=login';

      fetch(url, {
        method: 'POST',
        body: form_data
      })
        .then(res => res.json())
        .then(result => {
          console.log("handleLogin:", result);
          if (result.returnStatus.code === "0000") {
            window.location.reload();
          } else {
            this.showMessage("พบข้อผิดพลาด", result.returnStatus.topic);
          }
        });
    }
  }

  render() {
    return (
      <>
        <HeaderImage topic="สมัครสมาชิก / เข้าสู่ระบบ" />

        <div id="page-content" className="page-wrapper section">

          <div className="login-section mb-80">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="registered-customers">
                    <h6 className="widget-title border-left mb-50">เข้าสู่ระบบ</h6>
                    <form onSubmit={this.handleLogin}>
                      <div className="login-account p-30 box-shadow">
                        <input type="text" name="login_email" placeholder="Email Address"
                          value={this.state.login_email}
                          onChange={this.handleInputChange}
                          autoComplete="username"
                        />
                        <InputGroup>
                          <Form.Control
                            type={this.state.login_pass_view ? 'text' : 'password'}
                            name="login_pass" placeholder="รหัสผ่าน"
                            value={this.state.login_pass}
                            onChange={this.handleInputChange}
                            autoComplete="current-password"
                            aria-label="Password"
                            aria-describedby="basic-addon1"
                          />
                          <InputGroup.Text id="basic-addon1" className='px-3'>
                            {this.state.login_pass_view ?
                              <FaRegEye size={20} className='cursor-pointer' onClick={this.handleLoginPassView} />
                              :
                              <FaRegEyeSlash size={20} className='cursor-pointer' onClick={this.handleLoginPassView} />
                            }
                          </InputGroup.Text>
                        </InputGroup>
                        <Row>
                          <Col>
                            <button className="submit-btn-1 btn-hover-1" type="submit">เข้าสู่ระบบ</button>
                          </Col>
                          <Col className="text-right"><small><Link to="/ForgetPassword">ลืมรหัสผ่าน?</Link></small></Col>
                        </Row>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="new-customers">
                    <form onSubmit={this.handleRegister}>
                      <h6 className="widget-title border-left mb-50">สมัครสมาชิก</h6>
                      <div className="login-account p-30 box-shadow">
                        <div className="row">
                          <div className="col-sm-6">
                            <input type="text" name="regis_firstname"
                              placeholder="ชื่อ"
                              value={this.state.regis_firstname}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <input type="text" name="regis_lastname"
                              placeholder="นามสกุล"
                              value={this.state.regis_lastname}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-sm-12">
                            <input type="text" name="regis_email"
                              placeholder="Email Address"
                              value={this.state.regis_email}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="col-sm-12">
                            <InputGroup>
                              <Form.Control
                                type={this.state.regis_pass_view ? 'text' : 'password'}
                                name="regis_pass" placeholder="รหัสผ่าน"
                                value={this.state.regis_pass}
                                onChange={this.handleInputChange}
                                autoComplete="new-password"
                                aria-label="New Password"
                                aria-describedby="basic-addon2"
                              />
                              <InputGroup.Text id="basic-addon2" className='px-3'>
                                {this.state.regis_pass_view ?
                                  <FaRegEye size={20} className='cursor-pointer' onClick={this.handleRegisPassView} />
                                  :
                                  <FaRegEyeSlash size={20} className='cursor-pointer' onClick={this.handleRegisPassView} />
                                }
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <div className="col-sm-12">
                            <InputGroup>
                              <Form.Control
                                type={this.state.regis_pass_confirm_view ? 'text' : 'password'}
                                name="regis_pass_confirm" placeholder="ยืนยันรหัสผ่าน"
                                value={this.state.regis_pass_confirm}
                                onChange={this.handleInputChange}
                                autoComplete="confirm-password"
                                aria-label="Confirm Password"
                                aria-describedby="basic-addon3"
                              />
                              <InputGroup.Text id="basic-addon3" className='px-3'>
                                {this.state.regis_pass_confirm_view ?
                                  <FaRegEye size={20} className='cursor-pointer' onClick={this.handleRegisConfirmPassView} />
                                  :
                                  <FaRegEyeSlash size={20} className='cursor-pointer' onClick={this.handleRegisConfirmPassView} />
                                }
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <button className="submit-btn-1 btn-hover-1" type="submit">สมัครสมาชิก</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <Modal show={this.state.modalShow} onHide={this.handleClose} centered>
          <Modal.Header className="p-4" closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">{this.state.modalMessage}</Modal.Body>
          <Modal.Footer className="p-4">
            <Button variant="secondary" onClickCapture={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default Login