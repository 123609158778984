import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';

export class HomeAbout extends Component {
  render() {
    return (
      <>
        <div className="up-comming-product-section mt-60 mb-60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="up-comming-pro gray-bg clearfix">
                  <div className="up-comming-pro-img f-left">
                    <img src={this.props.datas?.set3 ? urlApi + '/storage/media/' + this.props.datas.set3 : 'img/default/no-photo.png'} alt="" />
                  </div>
                  <div className="up-comming-pro-info f-left">
                    <h3>{this.props.datas?.set1}</h3>
                    <p>{this.props.datas?.set2}</p>
                    <Link to="/About">อ่านต่อ</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default HomeAbout