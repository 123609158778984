import React, { Component } from 'react';
import Banner from '../Images/banner.jpg';

export class HeaderImage extends Component {
  constructor(props){
    super(props);

    this.state = {}
  }

  render() {
    return (
      <>
        <div className="breadcrumbs-section plr-200 mb-80 section">
          <div className="breadcrumbs overlay-bg" style={{backgroundImage: 'url("' + Banner + '")', backgroundSize: 'cover', backgroundPosition: 'right bottom'}}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumbs-inner">
                    <h1 className="breadcrumbs-title">{ this.props.topic }</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default HeaderImage