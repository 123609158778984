import React from 'react';
import { Link } from 'react-router-dom';
import WithRouter from '../Components/WithRouter';
import { BsDot } from 'react-icons/bs';
import { urlApi, urlApiUpper, useFullUrlApi } from '../Constants/Global';
import HeaderImage from '../Components/HeaderImage';
import CardItem from '../Components/CardItem';

function CategoryView({ datas, updateCart, handleChange }) {
  
  function handleChange(v_param) {
    handleChange(v_param);
  }

  return (
    <>
      <HeaderImage topic={datas?.category?.name} />

      <div id="page-content" className="page-wrapper section">
        <div className="shop-section mb-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 order-lg-2 order-1">
                <div className="shop-content">
                  <div className="row">
                    {datas?.products?.map((item, index) => (
                      <div className="col-lg-4 col-sm-12" key={index}>
                        <CardItem 
                          uid={item.id} 
                          name={item.product_name} 
                          price={item.price} 
                          cover={item.row_value ? (useFullUrlApi ? urlApi + '/storage/product/' + item.row_value : "../" + urlApi + '/storage/product/' + item.row_value) : item.row_value} 
                          sellingCaption={item.selling_caption}
                          handleChange={handleChange}
                          apiPath={'../'}
                          updateCart={updateCart}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 order-lg-1 order-2">
                <aside className="widget widget-categories box-shadow mb-30">
                  <h6 className="widget-title border-left mb-20">กลุ่มผลิตภัณฑ์</h6>
                  <div id="cat-treeview" className="product-cat">
                    <ul>
                      {datas?.categories?.map((item, index) => (
                        <li className="open" key={index}>
                          <Link to={"/Category/" + item.id}><BsDot />{item.detail}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </aside>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

CategoryView.defaultProps = {
  datas: {
    category: {},
    categories: [],
    products: []
  }
}

export default WithRouter(CategoryView)