import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import HeaderImage from '../Components/HeaderImage';
import { HiOutlineDocumentText } from 'react-icons/hi';
import NavbarProfile from '../Components/NavbarProfile';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

function Security(props) {

  const [profile, setProfile] = useState(props.datas.profile || {});

  const [pass_current, setPassword] = useState('');
  const [pass_new, setNewPassword] = useState('');
  const [pass_new_confirm, setConfirmPassword] = useState('');

  const [pass_current_view, setPassCurrentView] = useState(false);
  const [pass_new_view, setPassNewView] = useState(false);
  const [pass_new_confirm_view, setPassNewConfirmView] = useState(false);

  const [modalMessage, setModalMessage] = useState(false);
  const [modalMessageTitle, setModalMessageTitle] = useState('');
  const [modalMessageMessage, setModalMessageMessage] = useState('');

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === 'pass_current') {
      setPassword(value);
    } else if (name === 'pass_new') {
      setNewPassword(value);
    } else if (name === 'pass_new_confirm') {
      setConfirmPassword(value);
    } else {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [name]: value,
      }));
    }
  }

  const handlePassCurrentView = () => {
    setPassCurrentView(!pass_current_view);
  }

  const handlePassNewView = () => {
    setPassNewView(!pass_new_view);
  }

  const handlePassNewConfirmView = () => {
    setPassNewConfirmView(!pass_new_confirm_view);
  }

  const handleClose = () => {
    setModalMessage(false);
  }

  const handleShow = () => {
    setModalMessage(true);
  }

  const saveProfile = (event) => {
    console.log("saveProfile:", profile);
    event.preventDefault();

    if (pass_new !== '' && pass_new !== pass_new_confirm) {
      setModalMessageTitle("พบข้อผิดพลาด");
      setModalMessageMessage("กรุณายืนยันรหัสผ่านให้ตรงกัน");
      handleShow();
    } else if (pass_current === '') {
      setModalMessageTitle("พบข้อผิดพลาด");
      setModalMessageMessage("กรุณากรอกรหัสผ่านของท่าน");
      handleShow();
    } else {
      const formData = new FormData();
      formData.append("firstname", profile.firstname);
      formData.append("pass_current", pass_current);
      formData.append("pass_new", pass_new);
      formData.append("pass_new_confirm", pass_new_confirm);

      var url = urlApi + '/?module=profile&call=save';
      fetch(url, {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(result => {
          console.log("saveProfile", result);

          if (result.returnStatus.code === "0000") {
            setNewPassword('');
            setConfirmPassword('');
            setPassword('');
            setModalMessageTitle("ดำเนินการเรียบร้อย");
            setModalMessageMessage("บันทึกข้อมูลเรียบร้อย");
            handleShow();
          } else {
            setModalMessageTitle("พบข้อผิดพลาด");
            setModalMessageMessage(result.returnStatus.detail);
            handleShow();
          }
        });
    }
  }

  useEffect(() => {
    setProfile(props.datas.profile || {});
  }, [props.datas]);

  return (
    <>
      <HeaderImage topic="ความปลอดภัย" />

      <div id="page-content" className="page-wrapper section">

        <div className="login-section mb-80">
          <div className="container">
            <div className="row">
              <div className='col-lg-3'>
                <NavbarProfile />
              </div>

              <div className="col-lg-9">
                <div className="my-account-content" id="accordion">

                  <div className="card mb-15">
                    <div className="card-header" id="headingOne">
                      <h4 className="card-title mb-0">
                        <span data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          ความปลอดภัย
                        </span>
                      </h4>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                      <div className="card-body">
                        <form onSubmit={saveProfile}>
                          <div className="new-customers">
                            <div className="p-30">
                              <div className="row">

                                <div className="col-sm-6">
                                  <InputGroup>
                                    <Form.Control
                                      type={pass_new_view ? 'text' : 'password'}
                                      name="pass_new" placeholder="รหัสผ่านใหม่"
                                      value={pass_new}
                                      onChange={handleInputChange}
                                      autoComplete="new-password"
                                      aria-label="Password"
                                      aria-describedby="basic-addon1"
                                    />
                                    <InputGroup.Text id="basic-addon1" className='px-3'>
                                      {pass_new_view ?
                                        <FaRegEye size={20} className='cursor-pointer' onClick={handlePassNewView} />
                                        :
                                        <FaRegEyeSlash size={20} className='cursor-pointer' onClick={handlePassNewView} />
                                      }
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>

                                <div className="col-sm-6">
                                  <InputGroup>
                                    <Form.Control
                                      type={pass_new_confirm_view ? 'text' : 'password'}
                                      name="pass_new_confirm" placeholder="ยืนยันรหัสผ่านใหม่"
                                      value={pass_new_confirm}
                                      onChange={handleInputChange}
                                      autoComplete="new-confirm-password"
                                      aria-label="Password"
                                      aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Text id="basic-addon2" className='px-3'>
                                      {pass_new_confirm_view ?
                                        <FaRegEye size={20} className='cursor-pointer' onClick={handlePassNewConfirmView} />
                                        :
                                        <FaRegEyeSlash size={20} className='cursor-pointer' onClick={handlePassNewConfirmView} />
                                      }
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>

                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <InputGroup>
                                    <Form.Control
                                      type={pass_current_view ? 'text' : 'password'}
                                      name="pass_current" placeholder="กรอกรหัสผ่านปัจจุบันเพื่อบันทึกข้อมูล"
                                      value={pass_current}
                                      onChange={handleInputChange}
                                      autoComplete="current-password"
                                      aria-label="Password"
                                      aria-describedby="basic-addon3"
                                    />
                                    <InputGroup.Text id="basic-addon3" className='px-3'>
                                      {pass_current_view ?
                                        <FaRegEye size={20} className='cursor-pointer' onClick={handlePassCurrentView} />
                                        :
                                        <FaRegEyeSlash size={20} className='cursor-pointer' onClick={handlePassCurrentView} />
                                      }
                                    </InputGroup.Text>
                                  </InputGroup>
                                </div>
                                <div className="col-md-6">
                                  <button className="submit-btn-1 btn-hover-1" type="submit"
                                    value="register">บันทึก</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Modal show={modalMessage}>
        <Modal.Header>
          <Modal.Title>{modalMessageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessageMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

Security.defaultProps = {
  datas: {
    profile: {}
  }
}

export default Security