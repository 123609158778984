import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderImage from '../Components/HeaderImage';

function ConfirmRegister(props) {
  return (
    <>
      <HeaderImage topic="ยืนยันการสมัครสมาชิก" />

      <div id="page-content" className="page-wrapper section">
        <div className="shop-section mb-80">
          <div className="container">
            <div className="row">

              <div className="col-lg-12">
                <div className="shop-content">
                  <div className="row">
                    {props.datas.confirm ? (
                      <div className="col-lg-12 col-sm-12 text-center">
                        ยืนยันเรียบร้อย เข้าสู่ระบบ<Link to="/Profile">ที่นี่</Link>
                      </div>
                    ) : (
                      <div className="col-lg-12 col-sm-12 text-center">
                        ไม่สามารถยืนยันได้ ติดต่อเรา<Link to="/Contact">ที่นี่</Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ConfirmRegister.defaultProps = {
  datas: {
    confirm: false
  }
}

export default ConfirmRegister