import React, { useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import HeaderImage from '../Components/HeaderImage';
import LogoBank from '../Images/bangkok_bank.png';

function Payment() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalButtonLabel, setModalButtonLabel] = useState('ปิด');
  const [modalButtonHome, setModalButtonHome] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    payment_date: '',
    payment_time: '',
    order_number: '',
    price: '',
    remark: '',
    file: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleClose = () => {
    setModalShow(false);
  }

  const handleGoTo = (targetPage) => {
    console.log("handleGoTo", targetPage);
    setModalShow(false);

    navigate.push(`/${targetPage}`);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("id", id);
    // form.append('name', formData.name);
    // form.append('email', formData.email);
    // form.append('payment_date', formData.payment_date);
    // form.append('payment_time', formData.payment_time);
    // form.append('order_number', formData.order_number);
    // form.append('price', formData.price);
    form.append('remark', formData.remark);
    form.append('file[]', formData.file);

    var url = urlApi + '/?module=order&call=payment-submit';
    console.log("submitPayment :", url);

    fetch(url, {
      method: 'POST',
      body: form
    })
      .then(res => res.json())
      .then(result => {
        console.log("submitPayment Result", result);

        if (result.returnStatus.code === "0000") {
          setModalShow(true);
          setModalTitle('ดำเนินการเรียบร้อย');
          setModalMessage('แจ้งชำระเงินเรียบร้อย');
          setModalButtonLabel(<Link to="/" style={{ color: '#FFF' }}>ไปหน้าแรก</Link>);
          setModalButtonHome(true);
        } else {
          setModalShow(true);
          setModalTitle('พบข้อผิดพลาด');
          setModalMessage('ไม่สามารถแจ้งชำระเงินได้');
          setModalButtonLabel('ปิด');
          setModalButtonHome(false);
        }
      });
  };

  return (
    <>
      <HeaderImage topic="ยืนยันการชำระเงิน" />

      <section id="page-content" className="page-wrapper section">

        <div className="message-box-section mt--50 mb-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-center">
                <h4 className="mt-5 mb-3 fw-bold">รายละเอียดการชำระเงิน</h4>
                <ul>
                  <li>
                    <img src={LogoBank} alt="bangkok bank logo" style={{ width: '280px' }} className="mb-3" />
                  </li>
                  <li className="mb-2 font-default tx-dark"><b>ธนาคารกรุงเทพ</b></li>
                  <li className="mb-2 font-default tx-dark">สาขา <b>ถนนเอกชัย สมุทรสาคร</b></li>
                  <li className="mb-2 font-default tx-dark">บัญชีเลขที่ <b>871-015774-7</b></li>
                  <li className="mb-2 font-default tx-dark">ชื่อบัญชี <b>บจ. ตั้งเติบโต</b></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="message-box box-shadow white-bg">
                  <form form id="contact-form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className="blog-section-title border-left mb-30">กรอกข้อมูลในฟอร์มเพื่อแจ้งชำระเงิน</h4>
                      </div>
                      {/* <div className="col-lg-6">
                        <input type="text" name="name" placeholder="ชื่อผู้ติดต่อ *" onChange={handleInputChange} />
                      </div>
                      <div className="col-lg-6">
                        <input type="text" name="email" placeholder="อีเมล์/เบอร์โทรศัพท์ *" onChange={handleInputChange} />
                      </div>
                      <div className="col-lg-6">
                        <input type="date" name="payment_date" placeholder="วันที่ชำระ *" onChange={handleInputChange} />
                      </div>
                      <div className="col-lg-6">
                        <input type="time" name="payment_time" placeholder="เวลา *" onChange={handleInputChange} />
                      </div>
                      <div className="col-lg-6">
                        <input type="text" name="order_number" placeholder="เลขที่ออเดอร์ *" onChange={handleInputChange} />
                      </div>
                      <div className="col-lg-6">
                        <input type="text" name="price" placeholder="จำนวนเงิน *" onChange={handleInputChange} />
                      </div> */}
                      <div className="col-lg-12">
                        <input type="text" name="remark" placeholder="รายละเอียดเพิ่มเติม" onChange={handleInputChange} />
                      </div>
                      <div className="col-lg-12">
                        <label htmlFor="formFile" className="form-label" style={{ paddingLeft: '20px' }}>เอกสารการชำระเงิน</label>
                        <input className="form-custom" type="file" id="formFile" accept='image/*,.pdf' onChange={handleFileChange} />
                      </div>
                      <div className="col-lg-12 text-right">
                        <button className="submit-btn-1 btn-hover-1" type="submit">แจ้งชำระเงิน</button>
                      </div>
                    </div>
                  </form>
                  <p className="form-messege"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal size="sm" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="itx-primary">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="itx-dark">
          {modalMessage}
        </Modal.Body>
        <Modal.Footer className="btn ibt-primary p-4">
          {modalButtonHome ?
            <Button className="btn ibt-primary px-4" onClick={() => handleGoTo('Home')}>
              {modalButtonLabel}
            </Button>
            :
            <Button className="btn ibt-primary px-4" onClick={handleClose}>
              {modalButtonLabel}
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Payment