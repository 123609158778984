import React from 'react';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';

const NavbarProfile = () => {
  const logoutHandler = () => {
    console.log("logoutHandler");

    var url = urlApi + '/?module=profile&call=logout';
    console.log("submitPayment :", url);

    fetch(url, {
      method: 'POST'
    })
      .then(res => res.json())
      .then(result => {
        console.log("logoutHandler Result", result);

        if (result.returnStatus.code === "0000") {
          window.location.reload();
        }
      });
  }

  return (
    <div>
      <ul style={{marginLeft: '1.5rem'}}>
        <li>
          <Link to={'/Order'}>รายการสั่งซื้อ</Link>
        </li>
        <li>
          <Link to={'/Profile'}>ข้อมูลส่วนตัว</Link>
        </li>
        <li>
          <Link to={'/Security'}>ความปลอดภัย</Link>
        </li>
        <li>
          <Link to={'/Profile'} onClick={logoutHandler}>ออกจากระบบ</Link>
        </li>
      </ul>
    </div>
  )
}

export default NavbarProfile